@import url('https://fonts.googleapis.com/css?family=Quicksand&display=swap');

* {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  outline: none;
  clear: both;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: Tahoma, "Quicksand", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
  font-family: Tahoma "Quicksand", sans-serif;
}

